body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar{
  display: none !important;
}

@font-face {
  font-family: "GoogleSans";
  src: local("GoogleSans-Regular.ttf"), url("/src/assets/fonts/google-sans/GoogleSans-Regular.ttf") format("truetype");
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}



@font-face {
  font-family: "EuclidCircularB";
  src: local("Euclid Circular B Light.ttf"), url("/src/assets/fonts/euclid-b/Euclid Circular B Light.ttf") format("truetype");
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "EuclidCircularB";
  src: local("Euclid Circular B Italic.ttf"), url("/src/assets/fonts/euclid-b/Euclid Circular B Italic.ttf") format("truetype");
  font-display: swap;
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "EuclidCircularB";
  src: local("Euclid Circular B Regular.ttf"), url("/src/assets/fonts/euclid-b/Euclid Circular B Regular.ttf") format("truetype");
  font-display: swap;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "EuclidCircularB";
  src: local("Euclid Circular B Italic.ttf"), url("/src/assets/fonts/euclid-b/Euclid Circular B Italic.ttf") format("truetype");
  font-display: swap;
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "EuclidCircularB";
  src: local("Euclid Circular B Medium.ttf"), url("/src/assets/fonts/euclid-b/Euclid Circular B Medium.ttf") format("truetype");
  font-display: swap;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "EuclidCircularB";
  src: local("Euclid Circular B Medium Italic.ttf"), url("/src/assets/fonts/euclid-b/Euclid Circular B Medium Italic.ttf") format("truetype");
  font-display: swap;
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "EuclidCircularB";
  src: local("Euclid Circular B SemiBold.ttf"), url("/src/assets/fonts/euclid-b/Euclid Circular B SemiBold.ttf") format("truetype");
  font-display: swap;
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "EuclidCircularB";
  src: local("Euclid Circular B SemiBold Italic.ttf"), url("/src/assets/fonts/euclid-b/Euclid Circular B SemiBold Italic.ttf") format("truetype");
  font-display: swap;
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "EuclidCircularB";
  src: local("Euclid Circular B Bold.ttf"), url("/src/assets/fonts/euclid-b/Euclid Circular B Bold.ttf") format("truetype");
  font-display: swap;
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "EuclidCircularB";
  src: local("Euclid Circular B Bold Italic.ttf"), url("/src/assets/fonts/euclid-b/Euclid Circular B Bold Italic.ttf") format("truetype");
  font-display: swap;
  font-weight: 700;
  font-style: italic;
}
